<template>
  <div class="menu_wrapper_container box-shadow profile_password" v-loading="loading">
    <el-card class="setting_wrapper" style="margin-top: 8px">
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <el-row :gutter="10" class="trading-client">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.current_password"
              label="Old Password"
              prop="current_password"
            >
              <el-input
                type="password"
                v-model="formData.current_password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"> </el-col>
        </el-row>

        <el-row :gutter="10" class="trading-client">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="New Password" prop="new_password">
              <el-input
                :type="passwordType"
                v-model="formData.new_password"
                autocomplete="off"
              >
              </el-input>
              <span class="show-pwd" @click="showPwd('password')">
                <svg-icon icon-name="eye" />
              </span>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="Retype Password" prop="new_confirm_password">
              <el-input
                :type="confirmPasswordType"
                v-model="formData.new_confirm_password"
                autocomplete="off"
              ></el-input>
              <span class="show-pwd" @click="showPwd('confirmPasswordType')">
                <svg-icon icon-name="eye" />
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="10" class="trading-client">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item
            label="Transaction Password"
            prop="transaction_password"
          >
            <el-input
              type="password"
              v-model="formData.transaction_password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">

        </el-col>
      </el-row> -->
        <br>
        <el-row :gutter="10" class="trading-client">
          <el-col :span="24" class="left">
            <el-button type="primary" @click="sendChildFormData()"
              >Save</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";
import {  store } from "@/api/change-password";


export default {
  name: "ChangePassword",
  props: {
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      passwordType: "password",
      confirmPasswordType: "password",
      formRules: {},
    };
  },
  created() {
    this.formRules.current_password = requiredRule("current_password");
    this.formRules.new_password = requiredRule("new_password");
    this.formRules.new_confirm_password = requiredRule("new_confirm_password");
    // this.formRules.transaction_password = requiredRule("transaction_password");
  },
  methods: {
    sendChildFormData() {
      this.loading = true;
      if (this.formData.new_password != this.formData.new_confirm_password) {
         this.flashError('Please Enter Correct Retype Password');
      }
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          //   this.$emit("getChildFormData", this.formData);
          console.log(this.formData);
          this.loading = true;
          store(this.formData)
            .then((response) => {
              if (response.data.success === true) {
                this.dialogVisible = false;
                this.flashSuccess(response.data.message);
                this.formData = {}
                this.loading = false;
              } else {
                this.formErrors = this.validationErrors(response.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
      this.loading = false;
    },
    chnagepass() {
      this.$emit("Changepassword");
    },
    submitFormChangePassword() {},
  },
};
</script>
